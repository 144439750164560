#ie11 {
  .square {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 125px;
    margin: 20px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fdfdfd;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @media screen and ( max-width: 600px ) {
      margin: 10px; }
    &:hover {
      background-color: #f2f2f2; } }
  > .container {
    margin: 80px auto; text-align: center;
    > .title {
      margin: 0 10px 24px 0;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: -2px;
      color: rgba(0, 0, 0, 0.87); }
    > .label {
      margin: 30px 40px;
      font-size: 26px;
      letter-spacing: -0.43px;
      color: rgba(0, 0, 0, 0.87); }
    > .images {
      display: flex;
      justify-content: center;
      .image {
        width: 60px;
        height: 60px;
        object-fit: contain; } } }
  .flex {
    display: flex;
    align-items: center; }
  .chrome {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: rgba(0, 0, 0, 0.87); }
  .edge {
    font-family: SegoeUI;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.23px;
    color: rgba(0, 0, 0, 0.87); }
  .text-center {
    text-align: center; }
  .ask {
    font-size: 14px;
    letter-spacing: -0.35px;
    color: #646464; } }
