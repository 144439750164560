@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
body {
  margin: 0;
  font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: keep-all; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

// html
//// @media (min-width: 960px)
////   margin: 0 auto
////   max-width: 1200px
////   padding: 0 30px

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px; }

h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
  font-weight: normal;
  border-bottom: 0; }

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */; }

.react-player {
  position: absolute;
  top: 0;
  left: 0; }


.draftjs {
  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px; } }
